import React from 'react'
import styled from 'styled-components'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import serverWalletAPI from '../../apis/ServerWalletAPI'

interface CommonImageUploadProps {
  value?: string | undefined
  onChange?: (value: string | undefined) => void
}

const StyledCommonImageUpload = styled.div`
  position: relative;

  button {
    width: 120px;
    height: 120px;
    padding: 8px;

    &.empty {
      font-size: 40px;
    }

    img {
      height: 100px;
      width: 100px;
    }
  }

  input,
  .delete {
    position: absolute;
    display: inline-block;
    height: 120px;
    width: 120px;
    z-index: 2;
    opacity: 0;
  }
  .delete {
    height: 100px;
    width: 100px;
    top: 10px;
    left: 10px;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 20px;
    line-height: 100px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`

const CommonImageUpload: React.FC<CommonImageUploadProps> = ({
  value,
  onChange,
}) => {
  const handleUpload: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files
    if (!files) return
    const file = files[0]
    if (!file) return
    serverWalletAPI
      .uploadImage(file)
      .then((data) => {
        if (onChange) onChange(data.url)
      })
      .catch((e) => console.log(e))
  }

  const handleDelete = (): void => {
    if (onChange) onChange(undefined)
  }

  return (
    <StyledCommonImageUpload>
      {value ? (
        <>
          <div className="delete" onClick={handleDelete}>
            <DeleteOutlined />
          </div>
          <Button>
            <img src={value} alt="" />
          </Button>
        </>
      ) : (
        <>
          <input type="file" accept="image/*" onChange={handleUpload} />
          <Button type="dashed" className="empty">
            <UploadOutlined />
          </Button>
        </>
      )}
    </StyledCommonImageUpload>
  )
}

export default CommonImageUpload
