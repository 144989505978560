import { useCallback, useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import serverWalletAPI, {
  // databaseId,
  JobData,
} from '../../apis/ServerWalletAPI'

interface useJobsStateProps {
  jobs: JobData[]
  triggerHiddenJob: (project: JobData) => Promise<void>
  deleteJob: (job: JobData) => Promise<void>
  createJob: () => Promise<void>
  saveJob: (job: JobData) => Promise<void>
  swapJobs: (job: JobData, otherJob: JobData) => Promise<void>
}

function useJobsState(): useJobsStateProps {
  const [jobs, setJobs] = useState<JobData[]>([])

  const updateJobs = useCallback(() => {
    serverWalletAPI
      .getJobs()
      .then((jobs) => {
        setJobs(jobs)
      })
      .catch((e) => console.log(e))
  }, [setJobs])

  const triggerHiddenJob = useCallback(
    async (job: JobData) => {
      return await serverWalletAPI
        .triggerHiddenJob(job.id, job.hidden)
        .then(updateJobs)
    },
    [updateJobs]
  )

  const deleteJob = useCallback(
    async (job: JobData) => {
      return await serverWalletAPI.deleteJob(job.id).then(updateJobs)
    },
    [updateJobs]
  )

  const createJob = useCallback(async () => {
    return await serverWalletAPI.createJob().then(updateJobs)
  }, [updateJobs])

  const saveJob = useCallback(
    async (job: JobData) => {
      return await serverWalletAPI.saveJob(job).then(updateJobs)
    },
    [updateJobs]
  )

  const swapJobs = useCallback(
    async (job: JobData, otherJob: JobData): Promise<void> => {
      return await serverWalletAPI.swapJobs(job, otherJob).then(updateJobs)
    },
    [updateJobs]
  )

  useEffect(() => {
    updateJobs()
  }, [])

  return {
    jobs,
    triggerHiddenJob,
    deleteJob,
    createJob,
    saveJob,
    swapJobs,
  }
}

const JobsState = createContainer(useJobsState)

export default JobsState
