import React, { useEffect, useRef, useState } from 'react'
import {
  projectData,
  databaseId,
  projectTagData,
  createProjectPayload,
} from '../../apis/ServerWalletAPI'
import { Modal, Form, Input, Switch, Tag, message, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import CommonImageUpload from '../../components/CommonImageUpload'
import ProjectState from './state'

function getEmptyProjectData(groupId: databaseId): projectData {
  const emptyProjectData: projectData = {
    id: -1,
    groupId,
    name: '',
    link: '',
    logoUrl: '',
    tags: [],
    hidden: true,
  }

  return emptyProjectData
}

interface ProjectTagsProps {
  tags: projectTagData[]
  onDelete: (tag: projectTagData) => void
  onCreate: (text: string) => void
  onEdit: (tag: projectTagData, newText: string) => void
}

const ProjectTags: React.FC<ProjectTagsProps> = ({
  tags,
  onCreate,
  onDelete,
  onEdit,
}) => {
  const [create, setCreate] = useState(false)
  const [newValue, setNewValue] = useState('')
  const [editId, setEditId] = useState<databaseId>()
  const [editValue, setEditValue] = useState('')
  const inputRef = useRef<Input | null>()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [create, editId])

  const handleCreate = (): void => {
    if (newValue && !tags.map((v) => v.text).includes(newValue)) {
      onCreate(newValue)
    }
    setCreate(false)
    setNewValue('')
  }

  const handleStartEdit = (tag: projectTagData): void => {
    setEditId(tag.id)
    setEditValue(tag.text)
  }

  const handleEdit = (): void => {
    const tag = tags.find((t) => t.id === editId)
    if (!tag) return
    onEdit(tag, editValue)
    setEditId(undefined)
    setEditValue('')
  }

  return (
    <div className="project-tags">
      {tags.map((tag) =>
        tag.id === editId ? (
          <Input
            key={tag.id}
            ref={(d) => (inputRef.current = d)}
            size="small"
            className="tag-input"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            onBlur={handleEdit}
            onPressEnter={handleEdit}
          />
        ) : (
          <Tag key={tag.id} closable onClose={() => onDelete(tag)}>
            <span onClick={(e) => handleStartEdit(tag)}>{tag.text}</span>
          </Tag>
        )
      )}
      {create ? (
        <Input
          ref={(d) => (inputRef.current = d)}
          size="small"
          className="tag-input"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          onBlur={handleCreate}
          onPressEnter={handleCreate}
        />
      ) : (
        <Tag className="create-btn" onClick={() => setCreate(true)}>
          <PlusOutlined /> 新增标签
        </Tag>
      )}
    </div>
  )
}

const ProjectModal: React.FC = () => {
  const {
    portfolio,
    currentSelectProject,
    currentSelectGroupId,
    createProject,
    editProject,
    projectModalVisible,
    setProjectModalVisible,
  } = ProjectState.useContainer()
  const [form] = Form.useForm<projectData>()
  const isCreate = !currentSelectProject
  const [newTags, setNewTags] = useState<projectTagData[]>([])

  useEffect(() => {
    if (projectModalVisible) {
      const data = isCreate
        ? getEmptyProjectData(currentSelectGroupId ?? '-1')
        : currentSelectProject
      form.setFieldsValue({ ...data })
      setNewTags(
        isCreate ? [] : JSON.parse(JSON.stringify(currentSelectProject.tags))
      )
    }
  }, [projectModalVisible])

  const onFinish = (values: any): void => {
    const payload: createProjectPayload = {
      groupId: currentSelectGroupId,
      ...values,
    }
    if (isCreate) {
      if (newTags.length > 0) {
        payload.tags = newTags.map((t) => t.text)
      }
      createProject(payload)
        .then(() => {
          setProjectModalVisible(false)
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('创建成功')
        })
        .catch((e) => {
          console.log(e)
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error('创建失败，请稍后再试')
        })
    } else {
      if (newTags.length > 0) {
        payload.tags = newTags
      }
      payload.id = currentSelectProject.id
      editProject(payload)
        .then(() => {
          setProjectModalVisible(false)
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('修改成功')
        })
        .catch((e) => {
          console.log(e)
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error('修改失败，请稍后再试')
        })
    }
  }

  const onFinishFailed = (errorInfo: any): void => {
    console.log('Failed:', errorInfo)
  }

  const handleSubmit = (): void => {
    form.submit()
  }

  const handleTagCreate = (text: string): void => {
    setNewTags([
      ...newTags,
      {
        id: -new Date().getTime(),
        projectId: isCreate ? -1 : currentSelectProject.id,
        text,
      },
    ])
  }

  const handleTagDelete = (tag: projectTagData): void => {
    const index = newTags.indexOf(tag)
    if (index > -1) {
      newTags.splice(index, 1)
      setNewTags([...newTags])
    }
  }

  const handleTagEdit = (tag: projectTagData, newText: string): void => {
    tag.text = newText
    setNewTags([...newTags])
  }

  return (
    <Modal
      title={isCreate ? '新建项目' : '项目详情'}
      onOk={handleSubmit}
      visible={projectModalVisible}
      onCancel={() => setProjectModalVisible(false)}
    >
      <div id="projectModal">
        <Form
          layout="vertical"
          name="project"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="项目所属类别"
            name="groupId"
            rules={[{ required: true, message: '请选择项目所属类别' }]}
          >
            <Select>
              {portfolio.map((group) => (
                <Select.Option key={group.id} value={group.id}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="项目名称"
            name="name"
            rules={[{ required: true, message: '请输入项目名称' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="项目链接"
            name="link"
            rules={[{ required: true, message: '请输入项目链接' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Logo图片"
            name="logoUrl"
            rules={[{ required: true, message: '请上传Logo' }]}
          >
            <CommonImageUpload />
          </Form.Item>

          <Form.Item
            label="隐藏"
            name="hidden"
            rules={[{ required: true }]}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <ProjectTags
            tags={newTags}
            onCreate={handleTagCreate}
            onDelete={handleTagDelete}
            onEdit={handleTagEdit}
          />
        </Form>
      </div>
    </Modal>
  )
}

export default ProjectModal

export const ProjectGroupModal: React.FC = () => {
  const {
    // currentSelectProject,
    // currentSelectGroupId,
    // createProject,
    // editProject,
    createProjectGroup,
    projectGroupModalVisible,
    setProjectGroupModalVisible,
  } = ProjectState.useContainer()
  const [form] = Form.useForm<{ name: string }>()

  useEffect(() => {
    if (projectGroupModalVisible) {
      form.setFieldsValue({ name: '' })
    }
  }, [projectGroupModalVisible])

  const onFinish = (values: any): void => {
    createProjectGroup(values)
      .then(() => {
        setProjectGroupModalVisible(false)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('添加成功')
      })
      .catch((e) => {
        console.log(e)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error('添加失败，请稍后再试')
      })
  }

  const onFinishFailed = (errorInfo: any): void => {
    console.log('Failed:', errorInfo)
  }

  const handleSubmit = (): void => {
    form.submit()
  }

  return (
    <Modal
      title="新建项目"
      onOk={handleSubmit}
      visible={projectGroupModalVisible}
      onCancel={() => setProjectGroupModalVisible(false)}
    >
      <div id="projectModal">
        <Form
          layout="vertical"
          name="project"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="项目分类名称"
            name="name"
            rules={[{ required: true, message: '请输入项目分类名称' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
