import { SERVER_URL } from '../constants'
import axios, { AxiosInstance } from 'axios'
// import mockProjectImg from '../assets/img/icon_mock_project.svg'

export type databaseId = number | string

export interface JsonResponseData<T = any> {
  success: boolean
  data?: T
  error?: {
    message: string
    code: string | number
  }
}

export interface projectTagData {
  id: databaseId
  projectId: databaseId
  text: string
}

export interface projectData {
  id: databaseId
  groupId: databaseId
  name: string
  link: string
  logoUrl: string
  tags: projectTagData[]
  hidden: boolean
}

export interface projectGroupData {
  id: databaseId
  name: string
  projects: projectData[]
  hidden: boolean
}

export type portfolioData = projectGroupData[]

export interface attachmentData {
  filename: string
  url: string
}

export interface createProjectPayload {
  id?: databaseId
  groupId?: databaseId
  name?: string
  link?: string
  logoUrl?: string
  tags?: string[] | projectTagData[]
  hidden?: boolean
}

export interface createProjectGroupPayload {
  id?: string
  name?: string
  hidden?: boolean
}

type ConstantKind = 'parameter' | 'information' | 'ui'
type ConstantType = 'decimal' | 'string' | 'boolean'

export interface ConstantData {
  name: string
  kind: ConstantKind
  type: ConstantType
  memo: string | null
  readOnly: boolean
  value?: string | boolean | number | null
}

export interface JobData {
  id: databaseId
  title: string
  responsibilities: string[]
  requirements: string[]
  hidden: boolean
}

export class ServerWalletAPI {
  private readonly axios: AxiosInstance

  constructor() {
    this.axios = axios.create({ baseURL: SERVER_URL })
  }

  private getResponseData<T>(data: JsonResponseData<T>): T | undefined | null {
    if (data.success) {
      return data.data
    }
    return null
  }

  async getPortfolio(): Promise<portfolioData> {
    const resp = await this.axios.get('/api/v1/portfolio?hidden=1')
    const data = this.getResponseData<portfolioData>(resp.data)
    if (data) {
      return data
    }
    return []
  }

  async uploadImage(file: File): Promise<attachmentData> {
    const form = new FormData()
    form.append('file', file)
    const resp = await this.axios.post('/api/v1/utils/upload', form)
    const data = this.getResponseData<attachmentData>(resp.data)
    if (data) return data
    throw new Error('上传失败')
  }

  // project
  async createProject(payload: createProjectPayload): Promise<projectData> {
    const resp = await this.axios.post('/api/v1/project', payload)
    const data = this.getResponseData<projectData>(resp.data)
    if (data) return data
    throw new Error('创建失败')
  }

  async createProjectGroup(
    payload: createProjectGroupPayload
  ): Promise<projectGroupData> {
    const resp = await this.axios.post('/api/v1/project_group', payload)
    const data = this.getResponseData<projectGroupData>(resp.data)
    if (data) return data
    throw new Error('创建失败')
  }

  async editProject(payload: createProjectPayload): Promise<projectData> {
    const resp = await this.axios.patch(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/project/${payload.id!}`,
      payload
    )
    const data = this.getResponseData<projectData>(resp.data)
    if (data) return data
    throw new Error('修改失败')
  }

  async triggerHiddenProject(
    id: databaseId,
    current: boolean
  ): Promise<projectData> {
    const resp = await this.axios.patch(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/project/${id}`,
      {
        hidden: !current,
      }
    )
    const data = this.getResponseData<projectData>(resp.data)
    if (data) return data
    throw new Error('修改失败')
  }

  async deleteProject(id: databaseId): Promise<void> {
    return await this.axios.delete(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/project/${id}`
    )
  }

  async swapProjects(
    project1: projectData,
    project2: projectData
  ): Promise<void> {
    return await this.axios.post(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/project/${project1.id}/swap/${project2.id}`
    )
  }

  async swapBetweenProjects(
    origin: projectData,
    project1: projectData,
    project2: projectData
  ): Promise<void> {
    return await this.axios.post(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/project/${origin.id}/between/${project1?.id || 0}/${project2.id}`
    )
  }

  async editProjectGroup(
    payload: createProjectGroupPayload
  ): Promise<projectData> {
    const resp = await this.axios.patch(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/project_group/${payload.id!}`,
      payload
    )
    const data = this.getResponseData<projectData>(resp.data)
    if (data) return data
    throw new Error('修改失败')
  }

  async triggerHiddenProjectGroup(
    id: databaseId,
    current: boolean
  ): Promise<projectGroupData> {
    const resp = await this.axios.patch(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/project_group/${id}`,
      {
        hidden: !current,
      }
    )
    const data = this.getResponseData<projectGroupData>(resp.data)
    if (data) return data
    throw new Error('修改失败')
  }

  async swapProjectGroups(
    group1: projectGroupData,
    group2: projectGroupData
  ): Promise<void> {
    return await this.axios.post(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/project_group/${group1.id}/swap/${group2.id}`
    )
  }

  async getDashboardHtml(): Promise<string> {
    const resp = await this.axios.get('/api/v1/utils/constant/HOME_HTML')
    const data = this.getResponseData<ConstantData>(resp.data)
    if (data) return data.value as string
    return ''
  }

  async saveDashboardHtml(html: string): Promise<void> {
    return await this.axios.post('/api/v1/utils/home_html', { html })
  }

  async getWebHtml(): Promise<string> {
    const resp = await this.axios.get('/api/v1/utils/web_html')
    const data = this.getResponseData<{ html: string }>(resp.data)
    if (data) return data.html
    return ''
  }

  async saveWebHtml(html: string): Promise<void> {
    return await this.axios.post('/api/v1/utils/web_html', { html })
  }

  // jobs
  async getJobs(): Promise<JobData[]> {
    const resp = await this.axios.get('/api/v1/jobs?hidden=1')
    const data = this.getResponseData<JobData[]>(resp.data)
    if (data) {
      return data
    }
    return []
  }

  async triggerHiddenJob(id: databaseId, current: boolean): Promise<JobData> {
    console.log(current)
    const resp = await this.axios.patch(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/job/${id}`,
      {
        hidden: !current,
      }
    )
    const data = this.getResponseData<JobData>(resp.data)
    if (data) return data
    throw new Error('修改失败')
  }

  async deleteJob(id: databaseId): Promise<void> {
    return await this.axios.delete(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/job/${id}`
    )
  }

  async createJob(title = '新职位'): Promise<void> {
    return await this.axios.post(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      '/api/v1/job',
      {
        title,
      }
    )
  }

  async saveJob(job: JobData): Promise<void> {
    const payload: Partial<JobData> = { ...job }
    delete payload.id
    delete payload.hidden
    return await this.axios.patch(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/job/${job.id}`,
      payload
    )
  }

  async swapJobs(job: JobData, otherJob: JobData): Promise<void> {
    return await this.axios.post(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `/api/v1/job/${job.id}/swaporder/${otherJob.id}`
    )
  }
}

const serverWalletAPI = new ServerWalletAPI()

export default serverWalletAPI
