import { useState, useEffect, useCallback } from 'react'
import { createContainer } from 'unstated-next'
import serverWalletAPI, {
  databaseId,
  portfolioData,
  projectData,
  projectGroupData,
  createProjectPayload,
  createProjectGroupPayload,
} from '../../apis/ServerWalletAPI'

interface useProjectsStateProps {
  portfolio: portfolioData
  projectModalVisible: boolean
  setProjectModalVisible: (projectModalVisible: boolean) => void
  projectGroupModalVisible: boolean
  setProjectGroupModalVisible: (projectGroupModalVisible: boolean) => void
  currentSelectProject: projectData | undefined
  currentSelectGroupId: databaseId | undefined
  startCreateProject: (groupId: databaseId) => void
  createProject: (payload: createProjectPayload) => Promise<void>
  startEditProject: (project: projectData) => void
  editProject: (payload: createProjectPayload) => Promise<void>
  updatePortfolio: () => void
  triggerHiddenProject: (project: projectData) => Promise<void>
  deleteProject: (project: projectData) => Promise<void>
  swapProjects: (project1: projectData, project2: projectData) => Promise<void>
  swapBetweenProjects: (
    origin: projectData,
    project1: projectData,
    project2: projectData
  ) => Promise<void>
  createProjectGroup: (payload: createProjectGroupPayload) => Promise<void>
  editProjectGroup: (payload: createProjectGroupPayload) => Promise<void>
  triggerHiddenProjectGroup: (group: projectGroupData) => Promise<void>
  swapProjectGroups: (
    group1: projectGroupData,
    group2: projectGroupData
  ) => Promise<void>
}

function useProjectsState(): useProjectsStateProps {
  const [currentSelectProject, setCurrentSelectProject] =
    useState<projectData>()
  const [currentSelectGroupId, setCurrentSelectGroupId] = useState<databaseId>()
  const [projectModalVisible, setProjectModalVisible] = useState(false)
  const [projectGroupModalVisible, setProjectGroupModalVisible] =
    useState(false)
  const [portfolio, setPortfolio] = useState<portfolioData>([])

  const updatePortfolio = useCallback(() => {
    serverWalletAPI
      .getPortfolio()
      .then(setPortfolio)
      .catch((e) => console.log(e))
  }, [setPortfolio])

  const startCreateProject = useCallback(
    (groupId: databaseId) => {
      setCurrentSelectProject(undefined)
      setCurrentSelectGroupId(groupId)
      setProjectModalVisible(true)
    },
    [setCurrentSelectProject, setProjectModalVisible, setCurrentSelectGroupId]
  )

  const createProject = useCallback(
    async (payload: createProjectPayload) => {
      return await serverWalletAPI.createProject(payload).then(updatePortfolio)
    },
    [updatePortfolio]
  )

  const startEditProject = useCallback(
    (project: projectData) => {
      setCurrentSelectProject(project)
      setCurrentSelectGroupId(project.groupId)
      setProjectModalVisible(true)
    },
    [setCurrentSelectProject, setProjectModalVisible, setCurrentSelectGroupId]
  )

  const editProject = useCallback(
    async (payload: createProjectPayload) => {
      return await serverWalletAPI.editProject(payload).then(updatePortfolio)
    },
    [updatePortfolio]
  )

  const triggerHiddenProject = useCallback(
    async (project: projectData) => {
      return await serverWalletAPI
        .triggerHiddenProject(project.id, project.hidden)
        .then(updatePortfolio)
    },
    [updatePortfolio]
  )

  const deleteProject = useCallback(
    async (project: projectData) => {
      return await serverWalletAPI
        .deleteProject(project.id)
        .then(updatePortfolio)
    },
    [updatePortfolio]
  )

  const swapProjects = useCallback(
    async (project1: projectData, project2: projectData) => {
      return await serverWalletAPI
        .swapProjects(project1, project2)
        .then(updatePortfolio)
    },
    [updatePortfolio]
  )

  const swapBetweenProjects = useCallback(
    async (
      origin: projectData,
      project1: projectData,
      project2: projectData
    ) => {
      return await serverWalletAPI
        .swapBetweenProjects(origin, project1, project2)
        .then(updatePortfolio)
    },
    [updatePortfolio]
  )

  const createProjectGroup = useCallback(
    async (payload: createProjectGroupPayload) => {
      return await serverWalletAPI
        .createProjectGroup(payload)
        .then(updatePortfolio)
    },
    [updatePortfolio]
  )

  const editProjectGroup = useCallback(
    async (payload: createProjectGroupPayload) => {
      return await serverWalletAPI
        .editProjectGroup(payload)
        .then(updatePortfolio)
    },
    [updatePortfolio]
  )

  const triggerHiddenProjectGroup = useCallback(
    async (group: projectGroupData) => {
      return await serverWalletAPI
        .triggerHiddenProjectGroup(group.id, group.hidden)
        .then(updatePortfolio)
    },
    [updatePortfolio]
  )
  const swapProjectGroups = useCallback(
    async (group1: projectGroupData, group2: projectGroupData) => {
      return await serverWalletAPI
        .swapProjectGroups(group1, group2)
        .then(updatePortfolio)
    },
    [updatePortfolio]
  )

  useEffect(() => {
    updatePortfolio()
  }, [])

  return {
    portfolio,
    projectModalVisible,
    setProjectModalVisible,
    projectGroupModalVisible,
    setProjectGroupModalVisible,
    currentSelectProject,
    currentSelectGroupId,
    startCreateProject,
    createProject,
    startEditProject,
    editProject,
    updatePortfolio,
    triggerHiddenProject,
    deleteProject,
    swapProjects,
    swapBetweenProjects,
    createProjectGroup,
    editProjectGroup,
    triggerHiddenProjectGroup,
    swapProjectGroups,
  }
}

const ProjectsState = createContainer(useProjectsState)

export default ProjectsState
