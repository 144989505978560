import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import User from '../../store/user'
import { Layout, Menu, Button } from 'antd'
import { UserOutlined, LeftOutlined } from '@ant-design/icons'
import './style.scss'
import styled from 'styled-components'

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

interface MyLayoutProps {
  title?: string
  id?: string
}

const MyLayoutHeader = styled.div`
  padding: 0 24px;
  font-size: 16px;
  font-weight: 500;
`

const MyLayout: React.FC<MyLayoutProps> = ({ children, title, id }) => {
  const history = useHistory()
  const { isLogin } = User.useContainer()

  useEffect(() => {
    if (!isLogin) {
      history.push('/login')
    }
  }, [])

  return (
    <Layout>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        <div
          style={{
            height: 32,
            margin: 16,
            background: 'rgba(255, 255, 255, 0.2)',
          }}
        />
        <Menu theme="dark" mode="inline" defaultOpenKeys={['sub1']}>
          <SubMenu key="sub1" icon={<UserOutlined />} title="网站管理">
            <Menu.Item key="1" icon={<UserOutlined />}>
              <Link to="/projects">项目管理</Link>
            </Menu.Item>
            {/* <Menu.Item key="2" icon={<UserOutlined />}>
              <Link to="/reports">报告管理</Link>
            </Menu.Item> */}
            <Menu.Item key="4" icon={<UserOutlined />}>
              <Link to="/jobs">职位管理</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<UserOutlined />}>
              <Link to="/page_html">页面HTML</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout style={{ marginLeft: 200 }}>
        <Header style={{ padding: 0, background: '#fff' }}>
          <MyLayoutHeader>
            <Button type="text" onClick={() => history.goBack()}>
              <LeftOutlined />
            </Button>
            <span>{title}</span>
          </MyLayoutHeader>
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <div style={{ padding: 24, background: '#fff' }} id={id}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>有问题找IT</Footer>
      </Layout>
    </Layout>
  )
}

export default MyLayout
