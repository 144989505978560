import React, { useEffect, useState } from 'react'
import { JobData } from '../../apis/ServerWalletAPI'
import Layout from '../../components/Layout'
import JobsState from './state'
import { Button, Input, Popconfirm } from 'antd'
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  DeleteOutlined,
  // EditOutlined,
  PlusOutlined,
  // LeftOutlined,
  // RightOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  // CompressOutlined,
} from '@ant-design/icons'
import classnames from 'classnames'
import './style.scss'

const Job: React.FC<JobData & { index: number }> = ({ index, ...data }) => {
  const [editing, edit] = useState(false)
  const [editData, setEditData] = useState<JobData>(data)
  const { triggerHiddenJob, deleteJob, saveJob, jobs, swapJobs } =
    JobsState.useContainer()

  useEffect(() => {
    if (editing) {
      setEditData(JSON.parse(JSON.stringify(data)))
    }
  }, [editing])

  const handleChangeEditDataResponsibilities = (
    value: string,
    i: number
  ): void => {
    const responsibilities = editData.responsibilities
    responsibilities[i] = value
    setEditData({
      ...editData,
      responsibilities,
    })
  }

  const handleChangeEditDataRequirements = (value: string, i: number): void => {
    const requirements = editData.requirements
    requirements[i] = value
    setEditData({
      ...editData,
      requirements,
    })
  }

  const handleSave = (): void => {
    saveJob(editData)
      .then(() => {
        edit(false)
      })
      .catch((e) => console.log(e))
  }

  const handleSwap = (plus: number): void => {
    const target = jobs[index + plus]
    swapJobs(data, target).catch((e) => console.log(e))
  }

  return (
    <div
      className={classnames('job', {
        'hidden-job': data.hidden,
      })}
    >
      <div className="job-name">
        <span className="title">
          {editing ? (
            <Input
              value={editData.title}
              onChange={(e) =>
                setEditData({ ...editData, title: e.target.value })
              }
            />
          ) : (
            <span>{data.title}</span>
          )}
        </span>
        <span className="opt">
          {index > 0 && (
            <Button size="small" onClick={async () => handleSwap(-1)}>
              <ArrowUpOutlined />
            </Button>
          )}
          {index < jobs.length - 1 && (
            <Button size="small" onClick={async () => handleSwap(1)}>
              <ArrowDownOutlined />
            </Button>
          )}
          <Button
            size="small"
            onClick={async () => await triggerHiddenJob(data)}
          >
            {data.hidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </Button>
          <Popconfirm
            title="确定删除"
            // eslint-disable-next-line @typescript-eslint/return-await
            onConfirm={async () => await deleteJob(data)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
          {editing ? (
            <Button type="primary" onClick={handleSave}>
              保存
            </Button>
          ) : (
            <Button type="primary" onClick={() => edit(true)}>
              编辑
            </Button>
          )}
        </span>
      </div>
      <h2>岗位职责</h2>
      <div className="rows responsibilities">
        {editing ? (
          <ul>
            {editData.responsibilities.map((resp, i) => (
              <li key={i}>
                <Input
                  value={resp}
                  onChange={(e) =>
                    handleChangeEditDataResponsibilities(e.target.value, i)
                  }
                />
              </li>
            ))}
            <li>
              <Button
                type="dashed"
                size="small"
                style={{ width: '100%' }}
                onClick={() =>
                  handleChangeEditDataResponsibilities(
                    '',
                    editData.responsibilities.length
                  )
                }
              >
                <PlusOutlined />
              </Button>
            </li>
          </ul>
        ) : (
          <ul>
            {data.responsibilities.map((resp, i) => (
              <li key={i}>{resp}</li>
            ))}
          </ul>
        )}
      </div>
      <h2>岗位要求</h2>
      <div className="rows requirements">
        {editing ? (
          <ul>
            {editData.requirements.map((resp, i) => (
              <li key={i}>
                <Input
                  value={resp}
                  onChange={(e) =>
                    handleChangeEditDataRequirements(e.target.value, i)
                  }
                />
              </li>
            ))}
            <li>
              <Button
                type="dashed"
                size="small"
                style={{ width: '100%' }}
                onClick={() =>
                  handleChangeEditDataRequirements(
                    '',
                    editData.requirements.length
                  )
                }
              >
                <PlusOutlined />
              </Button>
            </li>
          </ul>
        ) : (
          <ul>
            {data.requirements.map((resp, i) => (
              <li key={i}>{resp}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

const Jobs: React.FC = () => {
  const { jobs, createJob } = JobsState.useContainer()

  return (
    <Layout title="职位管理" id="jobs">
      <div className="list">
        {jobs.map((job, i) => (
          <Job key={job.id} {...job} index={i} />
        ))}
      </div>
      <div className="new-job">
        <Button type="dashed" onClick={createJob}>
          <PlusOutlined />
          <span>新增职位</span>
        </Button>
      </div>
    </Layout>
  )
}

const StatedJobs: React.FC = () => {
  return (
    <JobsState.Provider>
      <Jobs />
    </JobsState.Provider>
  )
}

export default StatedJobs
