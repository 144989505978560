import React, { useEffect, useState, useContext } from 'react'
import {
  HashRouter as Router,
  Route,
  RouteProps,
  Switch,
  useLocation,
} from 'react-router-dom'
import Home from '../views/Home'
import Login from '../views/Login'
import Projects from '../views/Projects'
import PageHtml from '../views/PageHtml'
import Job from '../views/Job'

export enum RoutePath {
  Home = '/',
  Login = '/login',
  Projects = '/projects',
  PageHtml = '/page_html',
  Jobs = '/jobs',
}

export const RouterContext = React.createContext({
  to: '',
  from: '',
})

export interface Routes {
  from: string
  to: string
}

export const useRoute = (): Routes => {
  return useContext(RouterContext)
}

const RouterProvider: React.FC = ({ children }) => {
  const location = useLocation()
  const [route, setRoute] = useState<Routes>({
    to: location.pathname,
    from: location.pathname,
  })

  useEffect(() => {
    setRoute((prev) => ({ to: location.pathname, from: prev.to }))
  }, [location])

  return (
    <RouterContext.Provider value={route}>{children}</RouterContext.Provider>
  )
}

interface MibaoRouterProps extends RouteProps {
  key: string
  params?: string
  path: string
}

const routes: MibaoRouterProps[] = [
  {
    component: Home,
    exact: true,
    key: 'Home',
    path: RoutePath.Home,
  },
  {
    component: Login,
    exact: true,
    key: 'Login',
    path: RoutePath.Login,
  },
  {
    component: Projects,
    exact: true,
    key: 'Project',
    path: RoutePath.Projects,
  },
  {
    component: Job,
    exact: true,
    key: 'Job',
    path: RoutePath.Jobs,
  },
  {
    component: PageHtml,
    exact: true,
    key: 'PageHtml',
    path: RoutePath.PageHtml,
  },
]

export const Routers: React.FC = () => {
  return (
    <Router>
      <RouterProvider>
        <Switch>
          {routes.map((route) => (
            <Route
              {...route}
              key={route.key}
              path={`${route.path}${route.params ?? ''}`}
            />
          ))}
        </Switch>
      </RouterProvider>
    </Router>
  )
}
