import React from 'react'
import Layout from '../../components/Layout'
import './style.scss'

const Home: React.FC = () => {
  return (
    <Layout title="首页333">
      <div>欢迎222</div>
    </Layout>
  )
}

export default Home
