import React from 'react'
import styled from 'styled-components'

const StyledCommonTableTopOpts = styled.div`
  text-align: right;
  margin-bottom: 24px;

  button {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const CommonTableTopOpts: React.FC = ({ children }) => {
  return <StyledCommonTableTopOpts>{children}</StyledCommonTableTopOpts>
}
