import { useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'

interface useUserProps {
  isLogin: boolean
  login: (user: string, password: string) => Promise<{ success: boolean }>
}

function useUser(): useUserProps {
  const [isLogin, setIsLogin] = useState(false)

  // TODO:
  const login = useCallback(
    async (user: string, password: string) => {
      if (user === 'admin' && password === 'ldcapnb') {
        setIsLogin(true)
        return await Promise.resolve({ success: true })
      }
      return await Promise.resolve({ success: false })
    },
    [setIsLogin]
  )

  return {
    isLogin,
    login,
  }
}

const User = createContainer(useUser)

export default User
