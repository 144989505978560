import React, { useState, useEffect, useRef } from 'react'
import { Button, Popconfirm, message, Tag, Input } from 'antd'
import classnames from 'classnames'
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  LeftOutlined,
  RightOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CompressOutlined,
} from '@ant-design/icons'
import Layout from '../../components/Layout'
import { CommonTableTopOpts } from '../../components/CommonStyledComponent'
import { projectGroupData, projectData } from '../../apis/ServerWalletAPI'
import ProjectState from './state'
import ProjectModal, { ProjectGroupModal } from './modal'
import './style.scss'

interface ProjectProps extends projectData {
  prev: projectData | undefined
  next: projectData | undefined
  onStartSwapMode: (origin: projectData) => void
}

interface ProjectGroupProps extends projectGroupData {
  prev: projectGroupData | undefined
  next: projectGroupData | undefined
}

const Project: React.FC<ProjectProps> = (props) => {
  const { name, logoUrl, hidden, link, prev, next, onStartSwapMode } = props
  const {
    startEditProject,
    triggerHiddenProject,
    deleteProject,
    swapProjects,
  } = ProjectState.useContainer()

  const handleSwap = (target: projectData): void => {
    swapProjects(props, target)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('修改成功')
      })
      .catch((e) => {
        console.log(e)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error('修改失败，请稍后再试')
      })
  }

  return (
    <div className="project">
      <div className="project-logo">
        {prev && (
          <Button
            className="order-btn left"
            shape="circle"
            size="small"
            icon={<LeftOutlined />}
            onClick={() => handleSwap(prev)}
          />
        )}
        <Button
          className="order-btn center"
          shape="circle"
          size="small"
          icon={<CompressOutlined />}
          onClick={() => onStartSwapMode(props)}
        />
        {next && (
          <Button
            className="order-btn right"
            shape="circle"
            size="small"
            icon={<RightOutlined />}
            onClick={() => handleSwap(next)}
          />
        )}
        {hidden && (
          <div className="hidden-mask">
            <EyeInvisibleOutlined />
          </div>
        )}
        {link === '__NOT_SET__' && (
          <Tag className="no-link" color="red">
            未设置链接
          </Tag>
        )}
        {link !== '__NOT_SET__' && !link.startsWith('http') && (
          <Tag className="no-link" color="red">
            链接有误
          </Tag>
        )}
        <img src={logoUrl} alt="" />
      </div>
      <div className="project-name">{name}</div>
      <div className="project-opt">
        <Button size="small" onClick={() => startEditProject(props)}>
          <EditOutlined />
        </Button>
        <Button
          size="small"
          onClick={async () => await triggerHiddenProject(props)}
        >
          {hidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </Button>
        <Popconfirm
          title="确定删除"
          onConfirm={async () => await deleteProject(props)}
          okText="Yes"
          cancelText="No"
        >
          <Button size="small">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </div>
    </div>
  )
}

const ProjectGroup: React.FC<ProjectGroupProps> = (props) => {
  const [swapMode, setSwapMode] = useState(false)
  const [swapOrign, setSwapOrign] = useState<projectData>()
  const [edit, setEdit] = useState(false)
  const [editValue, setEditValue] = useState('')
  const inputRef = useRef<Input | null>()
  const { name, projects, id, hidden, prev, next } = props
  const {
    startCreateProject,
    triggerHiddenProjectGroup,
    swapProjectGroups,
    editProjectGroup,
    swapBetweenProjects,
  } = ProjectState.useContainer()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [edit])

  const handleSwap = (target: projectGroupData): void => {
    swapProjectGroups(props, target)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('修改成功')
      })
      .catch((e) => {
        console.log(e)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error('修改失败，请稍后再试')
      })
  }

  const handleStartEdit = (): void => {
    setEdit(true)
    setEditValue(name)
  }

  const handleEdit = (): void => {
    if (editValue) {
      editProjectGroup({ id: id as string, name: editValue })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('修改成功')
        })
        .catch((e) => {
          console.log(e)
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error('修改失败，请稍后再试')
        })
    }
    setEdit(false)
    setEditValue('')
  }

  const handleStartSwapMode = (originOrTarget: projectData): void => {
    if (swapMode) {
      if (swapOrign) {
        // 交换
        const targetIndex = projects.findIndex(
          (p) => p.id === originOrTarget.id
        )
        const prevTargetIndex = targetIndex - 1
        swapBetweenProjects(
          swapOrign,
          projects[prevTargetIndex],
          originOrTarget
        )
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('修改成功')
          })
          .catch((e) => {
            console.log(e)
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error('修改失败，请稍后再试')
          })
      }
      setSwapMode(false)
      setSwapOrign(undefined)
    } else {
      setSwapMode(true)
      setSwapOrign(originOrTarget)
    }
  }

  const handleMouseLeave = (): void => {
    if (swapMode) {
      setSwapMode(false)
      setSwapOrign(undefined)
    }
  }

  const handleMouseClick = (): void => {
    if (swapMode) {
      setSwapMode(false)
      setSwapOrign(undefined)
    }
  }

  return (
    <div
      className={classnames('project-group', {
        'hidden-group': hidden,
        'swap-mode': swapMode,
      })}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
    >
      <div className="group-name">
        <span>
          {edit ? (
            <Input
              ref={(d) => (inputRef.current = d)}
              className="tag-input"
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              onBlur={handleEdit}
              onPressEnter={handleEdit}
            />
          ) : (
            <span onClick={handleStartEdit}>{name}</span>
          )}
        </span>
        <span className="opt">
          {prev && (
            <Button
              onClick={() => handleSwap(prev)}
              icon={<ArrowUpOutlined />}
            />
          )}
          {next && (
            <Button
              onClick={() => handleSwap(next)}
              icon={<ArrowDownOutlined />}
            />
          )}
          <Button
            onClick={async () => await triggerHiddenProjectGroup(props)}
            icon={hidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          />
        </span>
      </div>
      <div className="group-items">
        {projects.map((p, i) => (
          <Project
            {...p}
            key={p.id}
            prev={projects[i - 1]}
            next={projects[i + 1]}
            onStartSwapMode={handleStartSwapMode}
          />
        ))}
        <div className="project new">
          <div className="project-logo">
            <Button type="dashed" onClick={() => startCreateProject(id)}>
              <PlusOutlined />
            </Button>
          </div>
          <div className="project-name">新增项目</div>
        </div>
      </div>
    </div>
  )
}

const Projects: React.FC = () => {
  const { portfolio, setProjectGroupModalVisible } = ProjectState.useContainer()

  // console.log(portfolio)

  return (
    <>
      <Layout title="项目管理" id="projects">
        <CommonTableTopOpts>
          <Button
            type="primary"
            onClick={() => setProjectGroupModalVisible(true)}
          >
            新建项目分类
          </Button>
        </CommonTableTopOpts>
        <div className="projects">
          {portfolio.map((g, i) => (
            <ProjectGroup
              {...g}
              key={g.id}
              prev={portfolio[i - 1]}
              next={portfolio[i + 1]}
            />
          ))}
        </div>
      </Layout>
      <ProjectModal />
      <ProjectGroupModal />
    </>
  )
}

const StatedProjects: React.FC = () => {
  return (
    <ProjectState.Provider>
      <Projects />
    </ProjectState.Provider>
  )
}

export default StatedProjects
