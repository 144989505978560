import React, { useEffect, useState } from 'react'
import { Button, Input, message } from 'antd'
import Layout from '../../components/Layout'
import serverWalletAPI from '../../apis/ServerWalletAPI'
import './style.scss'

const { TextArea } = Input

const PageHtml: React.FC = () => {
  const [dashboardHtml, setDashboardHtml] = useState('')
  const [webHtml, setWebHtml] = useState('')

  useEffect(() => {
    serverWalletAPI
      .getDashboardHtml()
      .then(setDashboardHtml)
      .catch((e) => console.log(e))
    serverWalletAPI
      .getWebHtml()
      .then(setWebHtml)
      .catch((e) => console.log(e))
  }, [])

  const handleSaveDashboardHtml = (): void => {
    serverWalletAPI
      .saveDashboardHtml(dashboardHtml)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('修改成功，请刷新页面')
      })
      .catch((e) => {
        console.log(e)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error('修改失败，请稍后再试')
      })
  }

  const handleSaveWebHtml = (): void => {
    serverWalletAPI
      .saveWebHtml(webHtml)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('修改成功，请刷新页面')
      })
      .catch((e) => {
        console.log(e)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error('修改失败，请稍后再试')
      })
  }

  return (
    <Layout title="页面HTML">
      <div id="pagehtml">
        <h1 className="tt">
          <span>后台Dashboard HTML</span>
          <div className="opt">
            <Button type="primary" onClick={handleSaveDashboardHtml}>
              保存
            </Button>
          </div>
        </h1>
        <div className="input">
          <TextArea
            value={dashboardHtml}
            onChange={(e) => setDashboardHtml(e.target.value)}
            rows={8}
          />
        </div>
        <h1 className="tt">
          <span>前端官网 HTML</span>
          <div className="opt">
            <Button type="primary" onClick={handleSaveWebHtml}>
              保存
            </Button>
          </div>
        </h1>
        <div className="input">
          <TextArea
            value={webHtml}
            onChange={(e) => setWebHtml(e.target.value)}
            rows={8}
          />
        </div>
      </div>
    </Layout>
  )
}

export default PageHtml
