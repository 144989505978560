/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState } from 'react'
import { Button, Input, message } from 'antd'
import './style.scss'
import User from '../../store/user'
import { useHistory } from 'react-router-dom'

const Login: React.FC = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const { login } = User.useContainer()
  const history = useHistory()

  const handleLogin = (): void => {
    login(username, password)
      .then(({ success }) => {
        if (success) {
          history.push('/')
          message.success('登录成功')
        } else {
          message.error('登录失败')
        }
      })
      .catch(() => {
        message.error('登录失败')
      })
  }

  return (
    <div id="login">
      <div className="form">
        <div>
          <Input
            placeholder="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <Input
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <Button type="primary" onClick={handleLogin}>
            登录
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login
